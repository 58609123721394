import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { ConfigService } from './config/config.service';


@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(
    public configService: ConfigService,
    private httpClient: HttpClient
  ) { 
  }

  async getLocations(): Promise<any> {
    return await this.httpClient.get(this.configService.config?.apiUrl+ '/api/locations?populate=deep,3&sort=city:ASC&pagination[start]=0&pagination[limit]=-1').toPromise();
  }

  async getRegions(): Promise<any> {
    return await this.httpClient.get(this.configService.config?.apiUrl+ '/api/regions?_limit=-1').toPromise();
  }

  async getSettings(): Promise<any> {
    return await this.httpClient.get(this.configService.config?.apiUrl+ '/api/configuration').toPromise();
  }

  async getOverlayData(overlayPath: string): Promise<any> {
    return await this.httpClient.get(overlayPath).toPromise();
  }
}
