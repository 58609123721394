import { Component } from '@angular/core';
import { HttpService } from './http.service'
import { ConfigService } from './config/config.service';
import { MarkedPipe } from './marked.pipe';
declare const google:any;

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'frontend';
  public locations: any;
  public settings: any;
  public map: any;
  public displayedColumns: string[] = ['name', 'street', 'postalcode', 'city', 'description'];
  public mapConsent: boolean = false;
  public mapLoaded: boolean = false;
  public markers: any = [];
  public infoWindow: any;

  constructor(
    private httpService: HttpService,
    private configService: ConfigService,
    private markedPipe: MarkedPipe,
  ) {
  }

  loadScript(url: string) {
    return new Promise((resolve, reject) => {
      const script = document.createElement('script');
      script.async = true;
      script.defer = true;
      script.onload = resolve;
      script.onerror = reject;
      script.src = url;
      script.id = 'maps-script';
      document.head.appendChild(script);
    });
  }

  async ngOnInit(): Promise<void> {
    this.locations = await this.getLocations();
    this.settings = await this.getSettings();
    console.log(this.locations);
    console.log(this.settings);
  }

  loadMapsScript() {
    const url = 'https://maps.googleapis.com/maps/api/js?key=' + this.configService.config?.gmKey;
    this.loadScript(url).then(() => this.initMap());
  }

  initMap() {

    const lat = this.settings.attributes.lat || 52.36942; //fallback to MMM Plaza
    const lng = this.settings.attributes.lon || 9.7413701;

    const zoom = this.settings.attributes.gmaps_default_zoom_level || 10;

    this.map = new google.maps.Map(document.querySelector("#map"),
    {
      zoom: zoom,
      center: {
        lat: lat,
        lng: lng
      }
    });
    this.mapLoaded = true;

    this.map.data.setStyle({
      fillColor: '#0066b3',
      fillOpacity: .3,
      strokeWeight: 2,
      strokeColor: '#0066b3'
    });

    this.createInfoWindow();
    this.locations.forEach((location: any) => {
      this.drawMarker(location);
    });
    this.drawOverlays();
  }

  async getRegions(): Promise<any> {
    const response = await this.httpService.getRegions();
    return response.data;
  }

  async getLocations(): Promise<any> {
    const response = await this.httpService.getLocations();
    return response.data;
  }

  async getSettings(): Promise<any> {
    const response = await this.httpService.getSettings();
    return response.data;
  }

  drawMarker(location: any) {
    let that = this;

    let icon = null;
    if(location.attributes.locationtype?.data?.attributes.icon.data.attributes.url) {
      icon = this.configService.config?.apiUrl + location.attributes.locationtype?.data?.attributes.icon.data.attributes.url;
    }

    let marker = new google.maps.Marker({
      position: { lat: location.attributes.lat, lng: location.attributes.lon },
      map: that.map,
      title: location.attributes.name,
      icon: icon,
    });
    marker.location_name = location.attributes.name;
    marker.location_street = location.attributes.street;
    marker.location_postalcode = location.attributes.postalcode;
    marker.location_city = location.attributes.city;
    marker.location_img = location.attributes.picture?.data ? this.configService.config?.apiUrl + location.attributes.picture?.data[0].attributes.url : '';
    marker.location_description = this.markedPipe.transform(location.attributes.description);
    marker.location_id = location.id;

    google.maps.event.addListener(marker, 'click', function(){
      let content = 
      `<div class="infowindow">` +
      `<div class="image"><img src="` + marker.location_img + `" /></div>` +
      `<div class="content">` +
      `<strong>` + marker.location_name + `</strong>` +
      `<p>` + marker.location_description + `</p>` +
      `<p>` + marker.location_street + `<br />` + marker.location_postalcode + ` ` + marker.location_city + `</p>` +
      `</div>` +
      `</div>`;

      that.infoWindow.setContent(content);
      that.infoWindow.open({
        anchor: marker,
        map: that.map,
      });
    });
  }

  createInfoWindow() {
    this.infoWindow = new google.maps.InfoWindow({
      content: 'test'
    });
  }

  async drawOverlays() {
    //get overlayfile from settings
    if(this.settings.overlay?.url) {
      let overlayData = await this.httpService.getOverlayData(this.configService.config?.apiUrl + this.settings.overlay?.url);
      if(overlayData) {
        this.addOverlay(overlayData, 'mapoverlay');
      }
    }

    //get overlayfiles from regions
    const regions = await this.getRegions();
    regions.forEach(async (region: any) => {
      if(region.overlay?.url) {
        const overlayData = await this.httpService.getOverlayData(this.configService.config?.apiUrl + region.overlay?.url);
        if(overlayData) {
          this.addOverlay(overlayData, region.name);
        }
      }
    });
  }

  addOverlay(overlayData: any, idPropertyName: string) {
    this.map.data.addGeoJson(overlayData, {'idPropertyName': idPropertyName})
  }

  sortOpeninghoursByDay(location: any) {
    let openinghoursByDay: any = {};

    if (location?.openinghours) {

      const weekdays = [
        'Montag',
        'Dienstag',
        'Mittwoch',
        'Donnerstag',
        'Freitag',
        'Samstag',
        'Sonntag',
      ];

      weekdays.forEach(day => {
        const result = this.locations?.openinghours.filter((item: any) => item.day === day);
        if (result.length) {
          openinghoursByDay[day] = result;
        }
      });
    }

    return openinghoursByDay;
  }

  onChange(event: any) {
    this.mapConsent = event.checked;
    if(this.mapConsent) {
      if(this.mapLoaded) {
        setTimeout(() => this.initMap(), 50);
      } else {
        this.loadMapsScript();
      }
    }
  }
}
