<div fxLayout="column" fxLayoutAlign="flex-start">
  <div id="map" *ngIf="mapConsent"></div>
  <div id="consent" *ngIf="!mapConsent">
    <mat-card class="consent-card">
      <mat-card-content>
        <p>
          <strong>
            Ergebnisse auf Karte anzeigen
          </strong>
        </p>
        <p>
          Diese Website nutzt Google Maps. Ich bin damit einverstanden, dass Google für die Darstellung von Karten und für eigene Zwecke die IP-Adresse meines Gerätes sowie weitere technische Daten erhält. Mit Aktivierung werden diese Daten an Google übermittelt. Weitere Details im Datenschutzhinweis.
        </p>
        <mat-slide-toggle
          labelPosition="before"
          (change)="onChange($event)"
          >
            Google Maps nutzen
        </mat-slide-toggle>
      </mat-card-content>
    </mat-card>
  </div>
  <div id="location-list">
    <table mat-table [dataSource]="locations">

      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef> Name </th>
        <td mat-cell *matCellDef="let location"> {{location.attributes.name}} </td>
      </ng-container>

      <ng-container matColumnDef="street">
        <th mat-header-cell *matHeaderCellDef> Strasse </th>
        <td mat-cell *matCellDef="let location"> {{location.attributes.street}} </td>
      </ng-container>

      <ng-container matColumnDef="postalcode">
        <th mat-header-cell *matHeaderCellDef> PLZ </th>
        <td mat-cell *matCellDef="let location"> {{location.attributes.postcode}} </td>
      </ng-container>

      <ng-container matColumnDef="city">
        <th mat-header-cell *matHeaderCellDef> Ort </th>
        <td mat-cell *matCellDef="let location"> {{location.attributes.city}} </td>
      </ng-container>

      <ng-container matColumnDef="description">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let location" [innerHTML]="location.attributes.description | marked"></td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: true"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;"></tr>
    </table>
  </div>
</div>
<router-outlet></router-outlet>