import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  config: Config | null = null;

  constructor(private http: HttpClient) { }

  async loadConfig() {
    const config = await this.http.get<Config>('./assets/config.json').toPromise();
    this.config = config;
  }

  getConfig() {
    return this.config;
  }
}

export interface Config {
  apiUrl: string;
  gmKey: string;
}
